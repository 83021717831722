import { AxiosRequestConfig } from 'axios';
import { Locale } from 'enums/locale';
import { VehicleType } from 'enums/vehicleTypes';
import {
  ExperienceSearchParameters,
  FilterParameters,
  FilterState,
  PaginatedExperienceWithBreadcrumbs,
  PaginatedVehicle,
  PaginatedVehicleWithBreadcrumbs,
  PaginationQueries,
  SearchMappedQueries,
  SearchParameters,
  SearchState,
} from 'types/search.type';
import { client } from 'utils/axios';
import qs from 'qs';
import { ssrRequest } from 'utils/ssrRequestFactory';
import { GetServerSidePropsContext } from 'next';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { BusinessModel } from 'enums/businessModel';
import queryString from 'query-string';
import { VehicleSummary } from 'types/vehicle.type';

class SearchService {
  async search(query: string): Promise<string[]> {
    const { data } = await client.get(`/v1/customer/search`, {
      params: {
        query,
      },
    });
    return data;
  }

  mapSearchLocations(data: SearchState[]): SearchState[] {
    const removedCountries = data
      .map((a) => a.children as SearchState[])
      .flat();
    const cities: SearchState[] = [];
    const removedCities: SearchState[] = [];
    removedCountries.forEach((a) => {
      removedCities.push(...(a.children as SearchState[]));
      cities.push({ ...a, children: undefined, hidden: true });
    });
    removedCities.forEach((region) => {
      if (
        region.children?.length === 1 &&
        region.slug === region.children[0].slug
      ) {
        region.children = undefined;
      }
    });
    return [removedCities, cities].flat();
  }

  async getAllSearchResults(lang: Locale): Promise<SearchState[]> {
    const { data } = await client.get<SearchState[]>(
      `/v2/customer/search/all`,
      {
        headers: {
          'Accept-Language': lang,
        },
      }
    );
    return searchService.mapSearchLocations(data);
  }

  async getAllExperienceSearchResults(lang: Locale): Promise<SearchState[]> {
    const { data } = await client.get<SearchState[]>(
      `/v1/customer/experience/locations`,
      {
        headers: {
          'Accept-Language': lang,
        },
      }
    );

    return searchService.mapSearchLocations(data);
  }

  async getVehicleList(
    { location, vehicleType }: { location?: string; vehicleType?: VehicleType },
    paginations: PaginationQueries,
    cfg?: AxiosRequestConfig
  ): Promise<PaginatedVehicleWithBreadcrumbs> {
    const pagination = qs.stringify(paginations, {
      arrayFormat: 'repeat',
    });
    const { data } = await client.get(
      `/v1/customer/search/vehicle/?${pagination}`,
      {
        params: {
          type: vehicleType,
          location,
        },
        ...(cfg ?? {}),
      }
    );
    return data;
  }

  async getCompleteVehicleList(
    paginations: PaginationQueries,
    cfg?: AxiosRequestConfig
  ): Promise<PaginatedVehicle> {
    const pagination = qs.stringify(paginations, {
      arrayFormat: 'repeat',
    });
    const { data } = await client.get(`/v1/customer/vehicle/?${pagination}`, {
      ...cfg,
    });
    return data;
  }

  async getFilterOptions() {
    const { data } = await client.get(`/v2/customer/vehicle/filter-options`, {
      hideError: true,
    });
    return data;
  }

  getFilterAndPaginationQueryParams(
    paginations: PaginationQueries,
    filters: FilterParameters,
    search: SearchParameters
  ) {
    const qsPaginations = qs.stringify(
      { pagination: paginations },
      {
        allowDots: true,
      }
    );

    const { numberOfPeople, flexDates, businessModel, ...searchRest } = search;

    const capacity = {
      capacity: {
        min: numberOfPeople,
      },
    };

    const filtersWithSearch: FilterParameters | SearchParameters = {
      ...filters,
      ...searchRest,
      flexDates: searchRest.startTime ? flexDates : undefined,
      businessModel: searchRest.startTime ? businessModel : undefined,
      ...capacity,
    };

    const qsFilters = qs.stringify(filtersWithSearch, {
      allowDots: true,
    });
    return `${qsPaginations}&${qsFilters}`;
  }

  async getCompleteVehicleListWithFilter(
    paginations: PaginationQueries,
    filters: FilterParameters,
    search: SearchParameters,
    cfg?: AxiosRequestConfig
  ): Promise<PaginatedVehicleWithBreadcrumbs> {
    const params = searchService.getFilterAndPaginationQueryParams(
      paginations,
      filters,
      search
    );

    const { data } = await client.get(`/v2/customer/vehicle?${params}`, {
      ...cfg,
    });
    return data;
  }

  async getCompleteVehicleListWithFilterSSR(
    paginations: PaginationQueries,
    filters: FilterParameters,
    search: SearchParameters,
    ctx: GetServerSidePropsContext
  ): Promise<PaginatedVehicleWithBreadcrumbs> {
    const params = searchService.getFilterAndPaginationQueryParams(
      paginations,
      filters,
      search
    );
    return ssrRequest(`/v2/customer/vehicle?${params}`, ctx);
  }

  async getCompleteExperienceListWithFilterSSR(
    pagination: PaginationQueries,
    search: ExperienceSearchParameters,
    ctx: GetServerSidePropsContext
  ): Promise<PaginatedExperienceWithBreadcrumbs> {
    const params = qs.stringify(
      {
        pagination,
        ...search,
      },
      {
        arrayFormat: 'repeat',
        allowDots: true,
      }
    );
    return ssrRequest(`/v1/customer/experience/search?${params}`, ctx);
  }

  async getCompleteExperienceListWithFilter(
    pagination: PaginationQueries,
    search: ExperienceSearchParameters,
    cfg?: AxiosRequestConfig
  ): Promise<PaginatedExperienceWithBreadcrumbs> {
    const params = qs.stringify(
      {
        pagination,
        ...search,
      },
      {
        arrayFormat: 'repeat',
        allowDots: true,
      }
    );

    const { data } = await client.get(
      `/v1/customer/experience/search?${params}`,
      {
        ...cfg,
      }
    );
    return data;
  }

  getSearchFilterQueries(queries: NextParsedUrlQuery): SearchMappedQueries {
    const filterMapper = {
      startTime: (val: string) => (val ? new Date(val) : val),
      endTime: (val: string) => (val ? new Date(val) : val),
      numberOfPeople: (val: string) => (val ? Number(val) : val),
      businessModel: (val: string) => val as BusinessModel,
      flexDates: (val: string) => val === 'true',
    };
    return Object.entries(queries).reduce((acc, [key, val]) => {
      if (key in filterMapper) {
        return {
          ...acc,
          [key]: filterMapper[key as keyof typeof filterMapper](val as string),
        };
      }
      return acc;
    }, {});
  }

  getFilterQueryParams(queries: string) {
    const mappedQs = queryString.parse(queries, {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'index',
    });
    return {
      onlyImmediatelyReservable: mappedQs.onlyImmediatelyReservable,
      onlySuitableForWinter: mappedQs.onlySuitableForWinter,
      canReserveForOneHour: mappedQs['minimumRentDuration.max'] === 1,
      rating: mappedQs['rating.min'],
      vehicleTypes: mappedQs.vehicleTypes,
      usageConditions: mappedQs.usageConditions,
      facilities: mappedQs.vehicleFacilities,
      priceRange: {
        max: mappedQs['priceRange.max'],
        min: mappedQs['priceRange.min'],
      },
    } as FilterState;
  }

  mapFilterStateToServerDto(val: FilterState) {
    return {
      vehicleTypes: val.vehicleTypes,
      ...(val.canReserveForOneHour
        ? {
            minimumRentDuration: {
              min: 0,
              max: 1,
            },
          }
        : {}),
      ...(val.onlyImmediatelyReservable
        ? { onlyImmediatelyReservable: val.onlyImmediatelyReservable }
        : {}),
      ...(val.onlySuitableForWinter
        ? { onlySuitableForWinter: val.onlySuitableForWinter }
        : {}),
      priceRange: {
        min: val.priceRange?.min,
        max: val.priceRange?.max,
      },
      usageConditions: val.usageConditions,
      vehicleFacilities: val.facilities,
      rating: {
        max: 5,
        min: val.rating,
      },
    };
  }

  formatFiltersToQueryParamString(val: FilterState) {
    const state = searchService.mapFilterStateToServerDto(val);
    return qs.stringify(state, {
      allowDots: true,
    });
  }

  getCheapestPriceForSeoSSR(
    ctx: GetServerSidePropsContext,
    params: { location?: string; vehicleType?: VehicleType }
  ): Promise<VehicleSummary> {
    return ssrRequest(`/v2/customer/vehicle/cheapest-seo`, ctx, { params });
  }
}

export const searchService = new SearchService();
