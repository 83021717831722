import { Locale } from 'enums/locale';
import { NextRouter } from 'next/router';
import { getLocalePrefix } from 'utils/getLocalePrefix';
import { cookieService } from './CookieService';
import { dateService } from './DateService';
import { linkService } from './LinkService';

export type SlugTranslations = Record<string, Record<Locale, string>>;

class LocalizationService {
  private _localeCookieKey = 'NEXT_LOCALE';
  public slugTranslations: SlugTranslations | null = null;
  private _pathTranslations: Record<string, Record<string, string>> = {
    en: {
      '/hakkimizda': '/about',
      '/basinda-biz': '/press-media',
      '/tekneler': '/boats',
      '/gizlilik': '/privacy',
      '/tekliflerim': '/my-offers',
      '/rezervasyonlarim': '/my-reservations',
      '/hizmetler': '/services',
      '/kisisel-bilgilerim': '/profile',
      '/yardim-merkezi': '/help-center',
      '/legal': '/legal',
      '/kampanyalar': '/campaigns',
      '/iletisim': '/contact',
      '/favorilerim': '/my-favorites',
      ...linkService.categoryLinkTranslations.reduce(
        (acc, item) => ({ ...acc, [item.tr]: item.en }),
        {}
      ),
    },
  };

  translatePathname(href: string, locale: Locale | undefined) {
    const localeTranslations = locale && this._pathTranslations[locale];

    if (!localeTranslations) {
      return;
    }

    const [trPath, enPath] =
      Object.entries(localeTranslations).filter((path) =>
        href.includes(path[0])
      )?.[0] || [];
    return href.replace(trPath, enPath);
  }

  translateFromRouterRoute(router: NextRouter, locale: Locale) {
    const { pathname, asPath, locale: prevLocale } = router;
    if (pathname.includes('tekneler')) {
      return linkService.translateVehicleLink(
        asPath,
        prevLocale as Locale,
        locale
      );
    }
    if (pathname.includes('tekne-turlari')) {
      return linkService.translateExperienceLink(
        this.slugTranslations?.slug?.[locale] || '',
        locale
      );
    }
    const localeTranslation = this.translatePathname(pathname, locale);
    const localePrefix = locale !== Locale.TR ? `/${locale}` : '';
    return localePrefix + (localeTranslation ? localeTranslation : pathname);
  }

  translateSlugs(asPath: string, locale: Locale): string | undefined {
    const slugs = asPath.match(/\[\w+\]/g);

    if (!slugs?.length || !this.slugTranslations) {
      return undefined;
    }

    if (slugs.length === 1 && slugs[0] === '[category]') {
      const location = this.slugTranslations.location?.[locale];
      const category = this.slugTranslations.category?.[locale];
      return (
        getLocalePrefix(locale) + (location ? location + '-' : '') + category
      );
    }

    slugs.forEach((slug) => {
      const slugWithoutBrackets = slug.slice(1, -1);
      const localizedSlug =
        this.slugTranslations![slugWithoutBrackets]?.[locale];
      asPath = asPath.replace(slug, localizedSlug);
    });
    return asPath;
  }

  getTranslatedRouteWithSlugs(router: NextRouter, locale: Locale) {
    const asPath =
      this.translateFromRouterRoute(router, locale) || router.asPath;
    const slugTranslated = this.translateSlugs(asPath, locale) ?? asPath;

    return slugTranslated?.endsWith('/en/')
      ? slugTranslated.slice(0, -1)
      : slugTranslated;
  }

  setServicesLocales(locale: Locale) {
    cookieService.setCookie(this._localeCookieKey, locale, {});
    dateService.setLocale(locale);
  }
}

export const localizationService = new LocalizationService();
