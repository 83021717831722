import classNames from 'classnames';
import { useBreakpoint } from 'hooks/useBreakpoints';
import useHeroIntersection from 'hooks/useNavbarIntersection';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';
import LocalizationAndUserDropdown from './LocalizationAndUserDropdowns';
import NavbarLogo from './NavbarLogo';
import SearchBar from './SearchBar';

const routesHideNavbarOnMobile = ['/message'];
const routesHideSearchbar = ['/partner', '/message'];
const routesHideUserAndLocalization = ['/partner'];
const routesWithHero = ['/partner', '/', '/yardim-merkezi'];

interface NavbarProps {
  className?: string;
}

const Navbar: FunctionComponent<NavbarProps> = ({ className }) => {
  const { pathname } = useRouter();
  const { intersecting } = useHeroIntersection(routesWithHero);
  const { isMobile } = useBreakpoint();
  const showSearchbar = useMemo(
    () => !routesHideSearchbar.some((route) => pathname === route),
    [pathname]
  );

  const showUserAndLocalization = useMemo(
    () => !routesHideUserAndLocalization.some((route) => pathname === route),
    [pathname]
  );

  const showTransparentNavbar = useMemo(
    () => routesWithHero.some((route) => pathname === route) && intersecting,
    [pathname, intersecting]
  );

  const isProductDetailPage = useMemo(() => {
    return ['/tekneler/[slug]', '/tekne-turlari/[slug]'].includes(pathname);
  }, [pathname]);

  if (isMobile && routesHideNavbarOnMobile.includes(pathname)) {
    return null;
  }

  return (
    <header
      className={classNames(
        'navbar top-0 z-300 h-20 w-full bg-white shadow md:h-24',
        {
          '-transparent': showTransparentNavbar,
          sticky: !intersecting && !isProductDetailPage,
          invisible: !intersecting && pathname === '/partner',
        },
        className
      )}
    >
      <div className="sm:tkv-container container mx-auto flex h-full items-center justify-between">
        <NavbarLogo
          wrapperClasses={intersecting ? '' : 'hidden'}
          intersecting={intersecting}
        />
        {showSearchbar ? (
          <SearchBar
            className={classNames('w-full md:w-1/2', {
              hidden: intersecting,
            })}
            from="navbar"
          />
        ) : null}
        {showUserAndLocalization ? (
          <div className="hidden w-1/4 items-center justify-end md:flex">
            <LocalizationAndUserDropdown intersecting={intersecting} />
          </div>
        ) : null}
      </div>
    </header>
  );
};

export default Navbar;
