import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface FavoriteStore {
  favoritedVehicleIds: number[];
  favoritedExperienceIds: number[];
  setFavoritedVehicleIds: (
    vehicles: FavoriteStore['favoritedVehicleIds']
  ) => void;
  setFavoritedExperienceIds: (
    experiences: FavoriteStore['favoritedExperienceIds']
  ) => void;
  getVehicleFavorited: (vehicleId: number) => boolean;
  getExperienceFavorited: (vehicleId: number) => boolean;
}

export const useFavoriteStore = create<FavoriteStore>()(
  devtools((set, get) => {
    return {
      favoritedVehicleIds: [],
      favoritedExperienceIds: [],
      setFavoritedVehicleIds: (vehicles) =>
        set((state) => {
          return {
            ...state,
            favoritedVehicleIds: vehicles,
          };
        }),
      setFavoritedExperienceIds: (experience) =>
        set((state) => {
          return {
            ...state,
            favoritedVehExperienceIds: experience,
          };
        }),
      getVehicleFavorited(vehicleId) {
        return (get().favoritedVehicleIds || []).includes(vehicleId);
      },
      getExperienceFavorited(experienceId) {
        return (get().favoritedExperienceIds || []).includes(experienceId);
      },
    };
  })
);
