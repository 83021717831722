import BButton from 'components/common/BButton';
import { useBreakpoint } from 'hooks/useBreakpoints';
import { useComponentDidUpdate } from 'hooks/useComponentDidUpdate';
import { useFirstRender } from 'hooks/useFirstRender';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { linkService } from 'services/LinkService';
import { useSearchState } from 'states/useSearchState';
import styles from 'styles/Layout.module.css';
import 'react-calendar/dist/Calendar.css';
import { SearchNumberOfPeopleSelector } from './Search/SearchNumberOfPeopleSelector';
import { SearchDivider } from './Search/SearchDivider';
import { SearchLocationSelector } from './Search/SearchLocationSelector';
import { SearchDateSelector } from './Search/SearchDateSelector';
import { dateService } from 'services/DateService';
import qs from 'qs';
import { SearchQueryKey } from 'enums/searchQueryKey';
import { cn } from 'utils/cn';
import Modal from 'components/common/Modal';
import { useSearchFromQuery } from 'hooks/useSearchFromQuery';
import { analyticsService } from 'services/AnalyticsService';
import { BusinessModel } from 'enums/businessModel';
import { getSearchDefaultHours } from 'utils/getSearchDefaultHours';
import CdnSvg from 'components/common/CdnSvg';
import { useFilterStore } from 'states/useFilterStore';
import { useLocale } from 'hooks/useLocale';
import { SearchExperienceLocationSelector } from './Search/SearchExperienceLocationSelector';
import { useExperienceSearchState } from 'states/useExperienceSearchState';
import { useExperienceSearchFromQuery } from 'hooks/useExperienceSearchFromQuery';

type ActiveSearch = 'rental' | 'experience';

interface SearchBarProps {
  className?: string;
  from: 'homepage' | 'navbar';
}

const SearchBar: FunctionComponent<SearchBarProps> = ({ className, from }) => {
  const { t } = useTranslation('navbar');
  const router = useRouter();

  const getDefaultSearchType = () => {
    if (linkService.isExperiencePage(router.asPath)) {
      return 'experience';
    }
    return 'rental';
  };

  const [activeSearch, setActiveSearch] = useState<ActiveSearch>(
    getDefaultSearchType()
  );

  useEffect(() => {
    setActiveSearch(getDefaultSearchType());
  }, [router.asPath]);

  const compLocationInputRef = useRef<HTMLInputElement>(null);
  const {
    allSearchData,
    locationInputRef,
    setLocationInputRef,
    setShowLocationDropdown,
    setFilteredSearchResults,
    searchValue,
    setSearchValue,
    setShowNumberOfPeopleDropdown,
    numberOfPeople,
    setShowDateDropdown,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    setNumberOfPeople,
    businessModel,
    isFlexSearch,
    endHour,
    startHour,
    setEndHour,
    setStartHour,
    selectedLocation,
    setSelectedLocation,
    setBusinessModel,
    showDateDropdown,
  } = useSearchState();
  const experienceSearchState = useExperienceSearchState();
  const { isMobile } = useBreakpoint();
  const { setStateFromQuery, filterSearchResults } = useSearchFromQuery();
  const { filterExperienceSearchResults } = useExperienceSearchFromQuery();
  const [showMobileModal, setShowMobileModal] = useState<boolean>(false);
  const { filterParams } = useFilterStore();
  const { locale } = useLocale();

  const clearAll = () => {
    setStartTime(null);
    setEndTime(null);
    setStartHour(null);
    setEndHour(null);
    setSearchValue('');
    setSelectedLocation(null);
    setBusinessModel(BusinessModel.HOURLY);
    setNumberOfPeople(0);
  };

  useComponentDidUpdate(() => {
    setShowLocationDropdown(false);
    locationInputRef?.current?.blur();
    setFilteredSearchResults(allSearchData);
    experienceSearchState.setFilteredSearchResults(
      experienceSearchState.allSearchData
    );
  }, [router.asPath]);

  useFirstRender(() => {
    setLocationInputRef(compLocationInputRef);
    filterSearchResults('');
    filterExperienceSearchResults('');
  });

  const onInputChange = (value: string) => {
    setSearchValue(value);
    filterSearchResults(value);
  };

  const onExperienceInputChange = (value: string) => {
    experienceSearchState.setSearchValue(value);
    filterExperienceSearchResults(value);
  };

  useEffect(() => {
    if (router.pathname.includes('category')) setStateFromQuery();
    else clearAll();
  }, [router.asPath]);

  const handleQueryParams = () => {
    let payloadStartTime = startTime;
    let payloadEndTime = endTime;

    if (businessModel === BusinessModel.HOURLY) {
      payloadStartTime = startTime;
      payloadEndTime = endTime;
      const { startHour: defaultStartHour, addEndOffset } =
        getSearchDefaultHours(payloadStartTime);

      const sh = startHour !== null ? startHour : defaultStartHour;
      const eh = endHour !== null ? endHour : addEndOffset(sh);

      setStartHour(sh);
      payloadStartTime?.setHours(sh, 0, 0, 0);
      setStartTime(payloadStartTime);

      setEndHour(eh);
      payloadEndTime?.setHours(eh, 0, 0, 0);
      setEndTime(payloadEndTime);

      if (payloadStartTime !== null && payloadEndTime !== null) {
        if (
          eh < sh &&
          dateService.isSameDate(payloadStartTime, payloadEndTime)
        ) {
          const newEndDate = dateService
            .getDayjs()(payloadEndTime)
            .add(1, 'day')
            .toDate();
          setEndTime(newEndDate);
          payloadEndTime = newEndDate;
        } else if (
          eh > sh &&
          !dateService.isSameDate(payloadStartTime, payloadEndTime)
        ) {
          payloadEndTime = new Date(payloadStartTime);
          payloadEndTime.setHours(eh, 0, 0, 0);
          setEndTime(payloadEndTime);
        }
      }
    } else {
      payloadEndTime && payloadEndTime.setHours(23, 59, 59, 999);
      setEndTime(payloadEndTime);
    }

    const toBeQuerifiedState: Record<SearchQueryKey, unknown> = {
      startTime:
        payloadStartTime !== null
          ? dateService.formatDateToServer(payloadStartTime)
          : null,
      endTime:
        payloadEndTime !== null
          ? dateService.formatDateToServer(payloadEndTime)
          : null,
      numberOfPeople: numberOfPeople,
      businessModel: payloadStartTime !== null ? businessModel : null,
      flexDates: payloadStartTime !== null ? isFlexSearch : null,
      tz: dateService.getTimeZone(),
    };

    const mappedQueriedState = Object.entries(toBeQuerifiedState).reduce(
      (acc, [key, val]) => ({ ...acc, ...(val ? { [key]: val } : {}) }),
      {}
    );

    return qs.stringify(
      {
        ...mappedQueriedState,
        flexDates: isFlexSearch,
        ...filterParams,
      },
      { allowDots: true }
    );
  };

  const handleSearchEnd = () => {
    try {
      const slugifiedSearch = selectedLocation?.slug;

      const baseLink = linkService.generateCategoryLink({
        locale,
        locationSlug: slugifiedSearch,
      });

      const queries = handleQueryParams();

      router.push(`${baseLink}?${queries}`);
      setShowLocationDropdown(false);
      setShowDateDropdown(false);
      setShowNumberOfPeopleDropdown(false);
      setShowMobileModal(false);
      locationInputRef?.current?.blur();
      analyticsService.fireSearchEvent({
        businessModel: businessModel,
        endTime: endTime,
        startTime: startTime,
        numberOfPeople: numberOfPeople,
        harbor: selectedLocation?.name,
      });
    } catch (error) {
      if (typeof error === 'string') {
        toast.error(error, { toastId: 'invalid-location-error' });
      }
    }
  };

  const datePickerLabel = useMemo(() => {
    if (!startTime) {
      return t('search.date.addDate');
    }
    if (businessModel === BusinessModel.HOURLY) {
      return dateService.getReadableShortRangeDateTime(
        startTime,
        startHour,
        endHour
      );
    } else {
      return dateService.getReadableShortRangeDate(startTime, endTime);
    }
  }, [businessModel, startTime, endTime, startHour, endHour, t]);

  const openLocationDropdown = () => {
    setShowLocationDropdown(true);
    setShowDateDropdown(false);
    setShowNumberOfPeopleDropdown(false);
  };

  const openDateDropdown = () => {
    setShowLocationDropdown(false);
    setShowDateDropdown(true);
    setShowNumberOfPeopleDropdown(false);
  };

  const openNumberOfPeopleDropdown = () => {
    setShowLocationDropdown(false);
    setShowDateDropdown(false);
    setShowNumberOfPeopleDropdown(true);
  };

  const searchWrapperClass = 'navbar-search text-base';
  const searchContainerClass = 'relative mx-auto';
  const searchBoxClass =
    'cursor-pointer rounded-full border bg-white shadow-sm transition-shadow duration-300 ease-linear hover:shadow-md';

  const openMobileModal = () => {
    setShowMobileModal(true);
    setShowLocationDropdown(true);
    setShowDateDropdown(false);
    setShowNumberOfPeopleDropdown(false);
  };

  const onExperienceSearchEnd = (path: string) => {
    router.push(path);
    setShowMobileModal(false);
  };

  if (isMobile) {
    const searchTitle = selectedLocation
      ? selectedLocation.name
      : t('search.title');
    const searchSubTitle = (() => {
      if (activeSearch === 'experience') {
        return t('search.descriptionExperience');
      }
      if (!startTime && !endTime && numberOfPeople < 1) {
        return t('search.description');
      }
      let label = '';
      label +=
        startTime && endTime ? datePickerLabel : t('search.date.addDate');
      label += ' • ';
      label +=
        numberOfPeople > 0
          ? t('search.numberOfPeople.person', { count: numberOfPeople })
          : t('search.numberOfPeople.placeholder');
      return label;
    })();
    return (
      <div className={cn(className, searchWrapperClass)}>
        <div
          className={cn(
            searchContainerClass,
            searchBoxClass,
            'flex w-max items-center gap-3 py-2.5 pl-4 pr-10 text-left'
          )}
          tabIndex={0}
          role="button"
          onClick={openMobileModal}
        >
          <CdnSvg src="search-black.svg" size={24} />
          <div>
            <div className="text-sm font-semibold">{searchTitle}</div>
            <div className="mt-1 text-xs text-slate-500">
              <span>{searchSubTitle}</span>
            </div>
          </div>
        </div>
        <Modal
          show={showMobileModal}
          setShow={() => setShowMobileModal(false)}
          modalClass="bg-stone-100 !px-0 flex flex-col"
          modalBodyClass="bg-stone-100 !mb-0 flex-[1_1_auto]"
          modalTitle={t('search.mobile.modalTitle')}
          modalTitleClass="bg-stone-100 px-6 shrink-0 grow-0"
          removeBottomMargin={true}
          removeSlideToClose={true}
        >
          <div className="mb-3 flex justify-evenly">
            <BButton
              className={cn('font-semibold', {
                underline: activeSearch === 'rental',
              })}
              variant="secondary"
              priority="text"
              onClick={() => setActiveSearch('rental')}
            >
              Tekne Kiralama
            </BButton>
            <BButton
              className={cn('font-semibold text-slate-500', {
                'text-primary-900 underline': activeSearch === 'experience',
              })}
              variant="secondary"
              priority="text"
              onClick={() => setActiveSearch('experience')}
            >
              Tekne Turları
            </BButton>
          </div>
          {activeSearch === 'rental' ? (
            <>
              <div
                className={cn('flex h-full flex-col space-y-4 overflow-auto', {
                  'pb-24': !showDateDropdown,
                })}
              >
                <SearchLocationSelector onInputChange={onInputChange} />
                <SearchDateSelector
                  selectedValue={datePickerLabel}
                  from={from}
                />
                {showDateDropdown ? null : <SearchNumberOfPeopleSelector />}
              </div>
              {showDateDropdown ? null : (
                <div className="fixed bottom-0 flex w-full justify-between bg-white p-4">
                  <BButton
                    variant="secondary"
                    priority="text"
                    className="underline"
                    onClick={clearAll}
                  >
                    {t('search.clearAll')}
                  </BButton>
                  <BButton onClick={handleSearchEnd}>
                    <div className="flex items-center gap-2">
                      <CdnSvg
                        src="search.svg"
                        size={20}
                        className="inline-block"
                      />
                      {t('search.mobile.action')}
                    </div>
                  </BButton>
                </div>
              )}
            </>
          ) : null}
          {activeSearch === 'experience' ? (
            <div
              className={cn('flex h-full flex-col space-y-4 overflow-auto', {
                'pb-24': !showDateDropdown,
              })}
            >
              <SearchExperienceLocationSelector
                onInputChange={onExperienceInputChange}
                onLocationSelect={onExperienceSearchEnd}
              />
            </div>
          ) : null}
        </Modal>
      </div>
    );
  }

  return (
    <div className={cn(className, searchWrapperClass)}>
      <div className={cn(searchContainerClass, 'w-max')} id={`search-${from}`}>
        <div className={cn(searchBoxClass, 'relative z-20 flex items-center')}>
          <div>
            <label>
              <span
                className="invisible block h-0 px-[1rem] text-base"
                aria-hidden="true"
              >
                {t('search.title')}
              </span>
              <input
                ref={compLocationInputRef}
                type="text"
                aria-readonly={isMobile}
                readOnly={isMobile}
                placeholder={t('search.title')}
                value={searchValue}
                onChange={
                  isMobile
                    ? undefined
                    : (event) => onInputChange(event.target.value)
                }
                className={styles.searchSection}
                onFocus={openLocationDropdown}
              />
            </label>
          </div>
          <SearchDivider />
          <div
            className="nowrap w-52 p-4 text-center"
            role="button"
            tabIndex={0}
            onClick={openDateDropdown}
          >
            {datePickerLabel}
          </div>
          <SearchDivider />
          <div
            role="button"
            tabIndex={0}
            className="nowrap w-32 p-4 pr-2 text-base"
            onClick={openNumberOfPeopleDropdown}
          >
            {numberOfPeople > 0
              ? t('search.numberOfPeople.person', { count: numberOfPeople })
              : t('search.numberOfPeople.placeholder')}
          </div>
          <BButton
            priority="none"
            title={t('search.buttonTitle')}
            className={cn(
              'mx-2 my-1 flex items-center rounded-full bg-primary-500 px-2 py-2 text-white transition-colors duration-200 ease-linear hover:bg-primary-600'
            )}
            onClick={handleSearchEnd}
          >
            <CdnSvg
              src="search.svg"
              size={20}
              className="inline-block h-5 w-5"
            />
          </BButton>
        </div>
        <SearchLocationSelector onInputChange={onInputChange} />
        <SearchDateSelector selectedValue={datePickerLabel} from={from} />
        <SearchNumberOfPeopleSelector />
      </div>
    </div>
  );
};

export default SearchBar;
