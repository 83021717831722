import { Currency } from 'enums/currency';
import { VehicleType } from 'enums/vehicleTypes';
import common from '../../locales/en/common.json';
import {
  ExperiencePaymentSuccessPayload,
  ExperienceSelectAndWishlistEventPayload,
  ExperienceSelectAndWishlistEventPayloadWithPrice,
  SelectAndWishlistEventPayload,
} from 'types/analytics.type';
import { vehicleNameConverterForAnalytics } from 'utils/vehicleNameCreator';
import { BusinessModel } from 'enums/businessModel';
import { dateService } from './DateService';
import { authService } from './AuthService';
import { SocialLoginType } from 'enums/socialLoginType';

class AnalyticsService {
  enVehicleTypes = common.boatTypes;

  addToCartVehicleBased({
    totalPrice,
    duration,
    vehiclePriceUnit,
    currency,
    hourlyPrice,
    vehicleId,
    vehicleType,
    vehicleName,
    region,
  }: {
    totalPrice: number;
    duration: number;
    vehiclePriceUnit: string;
    currency: Currency;
    hourlyPrice: number;
    vehicleId: number;
    vehicleType: VehicleType;
    vehicleName: string;
    region: string;
  }) {
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: currency,
        value: totalPrice,
        items: [
          {
            item_id: vehicleId,
            item_name: vehicleNameConverterForAnalytics(vehicleName),
            price: hourlyPrice,
            quantity: duration,
            item_brand: 'Teknevia',
            item_category: this.enVehicleTypes[vehicleType],
            item_variant: vehiclePriceUnit,
            // index: 0,
            location_id: region,
          },
        ],
      },
    });
  }

  viewCart({
    totalPrice,
    duration,
    vehiclePriceUnit,
    currency,
    hourlyPrice,
    vehicleId,
    vehicleType,
    vehicleName,
    region,
  }: {
    totalPrice: number;
    duration: number;
    vehiclePriceUnit: string;
    currency: Currency;
    hourlyPrice: number;
    vehicleId: number;
    vehicleType: VehicleType;
    vehicleName: string;
    region: string;
  }) {
    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: currency,
        value: totalPrice,
        items: [
          {
            item_id: vehicleId,
            item_name: vehicleNameConverterForAnalytics(vehicleName),
            price: hourlyPrice,
            quantity: duration,
            item_brand: 'Teknevia',
            item_category: this.enVehicleTypes[vehicleType],
            item_variant: vehiclePriceUnit,
            // index: 0,
            location_id: region,
          },
        ],
      },
    });
  }

  fireSearchEvent({
    startTime,
    endTime,
    numberOfPeople,
    harbor,
    businessModel,
  }: {
    startTime: Date | null;
    endTime: Date | null;
    numberOfPeople: number;
    harbor: string | undefined;
    businessModel: BusinessModel;
  }) {
    const term = (() => {
      let term = 'BM';
      if (startTime) term += '|ST';
      if (endTime) term += '|ET';
      if (harbor) term += '|H';
      if ((numberOfPeople || 0) > 0) term += '|P';
      return term;
    })();
    window.dataLayer.push({
      event: 'search',
      hebele: {
        search_term: term,
        startDate:
          startTime !== null
            ? dateService.formatSearchDate(startTime)
            : undefined,
        endDate:
          endTime !== null ? dateService.formatSearchDate(endTime) : undefined,
        numberOfPassenger: numberOfPeople,
        destination: harbor,
        travelClass: businessModel,
      },
    });
  }

  fireExperienceSearchEvent({
    date,
    numberOfPeople,
    harbor,
  }: {
    date?: Date;
    numberOfPeople?: number;
    harbor?: string;
  }) {
    const term = (() => {
      let term = 'EXP';
      if (harbor) term += '_H';
      if (date) term += '_D';
      if ((numberOfPeople || 0) > 0) term += '_C';
      return term;
    })();
    window.dataLayer.push({
      event: 'search',
      hebele: {
        search_term: term,
        date: date ? dateService.formatSearchDate(date) : undefined,
        ticketCount: numberOfPeople,
        destination: harbor,
      },
    });
  }

  paymentInfo({
    totalPrice,
    duration,
    vehiclePriceUnit,
    currency,
    hourlyPrice,
    vehicleId,
    vehicleType,
    vehicleName,
    region,
    coupon,
  }: {
    totalPrice: number;
    duration: number;
    vehiclePriceUnit: string;
    currency: Currency;
    hourlyPrice: number;
    vehicleId: number;
    vehicleType: VehicleType;
    vehicleName: string;
    region: string;
    coupon: string;
  }) {
    window.dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        value: totalPrice,
        currency: currency,
        coupon: coupon,
        items: [
          {
            item_id: vehicleId,
            item_name: vehicleNameConverterForAnalytics(vehicleName),
            price: hourlyPrice,
            quantity: duration,
            item_brand: 'Teknevia',
            item_category: this.enVehicleTypes[vehicleType],
            item_variant: vehiclePriceUnit,
            // index: 0,
            location_id: region,
          },
        ],
      },
    });
  }

  paymentInfoExperience(
    payload: ExperienceSelectAndWishlistEventPayloadWithPrice
  ) {
    window.dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        value: payload.totalPrice,
        currency: payload.currency,
        items: [
          {
            price: payload.price,
            quantity: payload.ticketCount,
            item_id: `EXP_${payload.experienceId}`,
            item_name: payload.experienceName,
            item_category: payload.experienceCategory,
            location_id: payload.regionTrName,
            item_brand: 'Teknevia',
          },
        ],
      },
    });
  }

  beginCheckout({
    totalPrice,
    duration,
    vehiclePriceUnit,
    currency,
    hourlyPrice,
    vehicleId,
    vehicleType,
    vehicleName,
    region,
  }: {
    totalPrice: number;
    duration: number;
    vehiclePriceUnit: string;
    currency: Currency;
    hourlyPrice: number;
    vehicleId: number;
    vehicleType: VehicleType;
    vehicleName: string;
    region: string;
  }) {
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        value: totalPrice,
        currency: currency,
        items: [
          {
            item_id: vehicleId,
            item_name: vehicleNameConverterForAnalytics(vehicleName),
            price: hourlyPrice,
            quantity: duration,
            item_brand: 'Teknevia',
            item_category: this.enVehicleTypes[vehicleType],
            item_variant: vehiclePriceUnit,
            //  "index": 0,
            location_id: region,
          },
        ],
      },
    });
  }

  beginCheckoutExperience(
    payload: ExperienceSelectAndWishlistEventPayloadWithPrice
  ) {
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        value: payload.totalPrice,
        currency: payload.currency,
        items: [
          {
            price: payload.price,
            quantity: payload.ticketCount,
            item_id: `EXP_${payload.experienceId}`,
            item_name: payload.experienceName,
            item_category: payload.experienceCategory,
            location_id: payload.regionTrName,
            item_brand: 'Teknevia',
          },
        ],
      },
    });
  }

  paymentSuccess({
    totalPrice,
    duration,
    vehiclePriceUnit,
    currency,
    hourlyPrice,
    vehicleId,
    vehicleType,
    vehicleName,
    region,
    transactionId,
    comission,
  }: {
    totalPrice: number;
    duration: number;
    vehiclePriceUnit: string;
    currency: Currency;
    hourlyPrice: number;
    vehicleId: number;
    vehicleType: VehicleType;
    vehicleName: string;
    region: string;
    transactionId: string;
    comission: number;
  }) {
    window.dataLayer.push({
      event: 'purchase',
      profit: comission,
      ecommerce: {
        transaction_id: transactionId,
        currency: currency,
        value: totalPrice,
        tax: comission * 0.2,
        items: [
          {
            item_id: vehicleId,
            item_name: vehicleNameConverterForAnalytics(vehicleName),
            price: hourlyPrice,
            quantity: duration,
            item_brand: 'Teknevia',
            item_category: this.enVehicleTypes[vehicleType],
            item_variant: vehiclePriceUnit,
            index: 0,
            location_id: region,
          },
        ],
      },
    });
  }

  paymentSuccessExperience(payload: ExperiencePaymentSuccessPayload) {
    window.dataLayer.push({
      event: 'purchase',
      comission: payload.totalPrice * 0.3,
      ecommerce: {
        transaction_id: payload.transactionId,
        value: payload.totalPrice,
        currency: payload.currency,
        tax: payload.totalPrice * 0.3,
        items: [
          {
            price: payload.price,
            quantity: payload.ticketCount,
            item_id: `EXP_${payload.experienceId}`,
            item_name: payload.experienceName,
            item_category: payload.experienceCategory,
            location_id: payload.regionTrName,
            item_brand: 'Teknevia',
          },
        ],
      },
    });
  }

  vehicleCardClick({
    currency,
    hourlyPrice,
    vehicleId,
    vehicleType,
    vehicleName,
    region,
    asPath,
    vehiclePriceUnit,
  }: SelectAndWishlistEventPayload) {
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        items: [
          {
            item_id: vehicleId,
            item_name: vehicleNameConverterForAnalytics(vehicleName),
            price: hourlyPrice,
            item_brand: 'Teknevia',
            item_category: this.enVehicleTypes[vehicleType],
            item_variant: vehiclePriceUnit,
            // index: "(0'den Başlayıp gidicek)",
            location_id: region,
          },
        ],
        item_list_name: asPath,
        currency: currency,
      },
    });
  }

  experienceCardClick(payload: ExperienceSelectAndWishlistEventPayload) {
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        items: [
          {
            item_id: `EXP_${payload.experienceId}`,
            item_name: payload.experienceName,
            item_category: payload.experienceCategory,
            location_id: payload.regionTrName,
            item_brand: 'Teknevia',
          },
        ],
        item_list_name: payload.asPathOfPreviousPage,
      },
    });
  }

  addToFavorite({
    currency,
    hourlyPrice,
    vehicleId,
    vehicleType,
    vehicleName,
    region,
    asPath,
    vehiclePriceUnit,
  }: SelectAndWishlistEventPayload) {
    window.dataLayer.push({
      event: 'add_to_wishlist',
      ecommerce: {
        items: [
          {
            item_id: vehicleId,
            item_name: vehicleNameConverterForAnalytics(vehicleName),
            price: hourlyPrice,
            item_brand: 'Teknevia',
            item_category: this.enVehicleTypes[vehicleType],
            item_variant: vehiclePriceUnit,
            // index: "(0'den Başlayıp gidicek)",
            location_id: region,
          },
        ],
        item_list_name: asPath,
        currency: currency,
      },
    });
  }

  vehicleDetailPageView({
    currency,
    hourlyPrice,
    vehicleId,
    vehicleType,
    vehicleName,
    region,
    asPathOfPreviousPage,
    vehiclePriceUnit,
  }: {
    currency: Currency;
    hourlyPrice: number;
    vehicleId: number;
    vehiclePriceUnit: string;
    vehicleType: VehicleType;
    vehicleName: string;
    region: string;
    asPathOfPreviousPage: string;
  }) {
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: vehicleId,
            item_name: vehicleNameConverterForAnalytics(vehicleName),
            price: hourlyPrice,
            item_brand: 'Teknevia',
            item_category: this.enVehicleTypes[vehicleType],
            // index: 0,
            item_variant: vehiclePriceUnit,
            location_id: region,
          },
        ],
        currency: currency,
        item_list_name: asPathOfPreviousPage, // onceki sayfanin as pathi
      },
    });
  }

  experienceDetailPageView({
    experienceId,
    experienceName,
    experienceCategory,
    regionTrName,
    asPathOfPreviousPage,
  }: ExperienceSelectAndWishlistEventPayload) {
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: `EXP_${experienceId}`,
            item_name: experienceName,
            item_category: experienceCategory,
            location_id: regionTrName,
            item_brand: 'Teknevia',
          },
        ],
        item_list_name: asPathOfPreviousPage,
      },
    });
  }

  addToFavoriteExperience({
    experienceId,
    experienceName,
    experienceCategory,
    regionTrName,
    asPathOfPreviousPage,
  }: ExperienceSelectAndWishlistEventPayload) {
    window.dataLayer.push({
      event: 'add_to_wishlist',
      ecommerce: {
        items: [
          {
            item_id: `EXP_${experienceId}`,
            item_name: experienceName,
            item_category: experienceCategory,
            location_id: regionTrName,
            item_brand: 'Teknevia',
          },
        ],
        item_list_name: asPathOfPreviousPage,
      },
    });
  }

  userInfo() {
    if (typeof window !== 'undefined') {
      const userInfo = authService.getUserInfo();
      let eventPayload = {
        event: 'userInfo',
        userInfo: {
          category_path: window.location.href,
          custumer_id: '',
          customer_login_status: 'false',
          user_custumer_type: 'GUEST',
          user_email: '',
          user_first_name: '',
          user_last_name: '',
          user_phone: '',
          user_country: '',
          user_since_last_purchase: '',
          user_since_last_session: '',
        },
      };
      if (userInfo) {
        eventPayload = {
          event: 'userInfo',
          userInfo: {
            category_path: window.location.href,
            custumer_id: `${userInfo.id}`,
            customer_login_status: 'true',
            user_custumer_type: 'LOGIN',
            user_email: userInfo.email,
            user_first_name: userInfo.firstName,
            user_last_name: userInfo.lastName,
            user_phone: userInfo.phoneNumber,
            user_country: userInfo.country,
            user_since_last_purchase: (userInfo.sinceLastPurchase ?? '')
              .split('/')
              .reverse()
              .join(''),
            user_since_last_session: (userInfo.sinseLastSession ?? '')
              .split('/')
              .reverse()
              .join(''),
          },
        };
      }
      window.dataLayer.push(eventPayload);
    }
  }

  remarketingHomePageView() {
    window.dataLayer.push({
      event: 'fireRemarketingTag',
      google_tag_params: {
        ecomm_pagetype: 'home',
      },
    });
  }

  // !on hold NOT_REGISTERED
  // remarketingin olmadigi diger butun sayfalarda atilacak
  remarketingOtherPageView() {
    window.dataLayer.push({
      event: 'fireRemarketingTag',
      google_tag_params: {
        ecomm_pagetype: 'other',
      },
    });
  }

  remarketingPurchaseComplete({
    vehicleId,
    vehicleType,
    vehicleName,
  }: {
    vehicleId: number;
    vehicleType: VehicleType;
    vehicleName: string;
  }) {
    const category = this.enVehicleTypes[vehicleType];
    window.dataLayer.push({
      event: 'fireRemarketingTag',
      google_tag_params: {
        ecomm_pagetype: 'purchase',
        ecomm_prodid: [`${vehicleId}`],
        ecomm_pcat: [category],
        ecomm_pname: [vehicleNameConverterForAnalytics(vehicleName)],
      },
    });
  }

  remarketingProductView({
    vehicleId,
    vehicleType,
  }: {
    vehicleId: number;
    vehicleType: VehicleType;
  }) {
    window.dataLayer.push({
      event: 'fireRemarketingTag',
      google_tag_params: {
        ecomm_pagetype: 'product',
        ecomm_prodid: [`${vehicleId}`],
        ecomm_pcat: [this.enVehicleTypes[vehicleType]],
      },
    });
  }

  signUp(platform: SocialLoginType | 'LOCAL') {
    window.dataLayer.push({
      event: 'sign_up',
      params: {
        method: platform,
      },
    });
  }

  login() {
    window.dataLayer.push({
      event: 'login',
    });
  }

  logEvent(event: Record<string, string>) {
    window.dataLayer.push(event);
  }
}

export const analyticsService = new AnalyticsService();
