import { Locale } from 'enums/locale';
import { RefObject } from 'react';
import { SearchState } from 'types/search.type';
import { NonFunctionStatePropertyNames } from 'types/state.type';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

type SetterFunction<T> = (val: T) => void;

interface ExperienceSearchStore {
  allSearchData: SearchState[];
  setAllSearchData: SetterFunction<SearchState[]>;
  searchLocale: Locale | null;
  setSearchLocale: SetterFunction<Locale | null>;

  showLocationDropdown: boolean;
  setShowLocationDropdown: SetterFunction<boolean>;
  searchValue: string;
  setSearchValue: SetterFunction<string>;
  filteredSearchResults: SearchState[];
  setFilteredSearchResults: SetterFunction<SearchState[]>;
  locationInputRef: RefObject<HTMLInputElement> | null;
  setLocationInputRef: SetterFunction<RefObject<HTMLInputElement> | null>;
  selectedLocation: SearchState | null;
  setSelectedLocation: SetterFunction<SearchState | null>;
}

export const useExperienceSearchState = create<ExperienceSearchStore>()(
  devtools((set) => {
    const setter =
      (key: NonFunctionStatePropertyNames<ExperienceSearchStore>) =>
      (val: ExperienceSearchStore[typeof key]) =>
        set((state) => ({ ...state, [key]: val }));
    return {
      allSearchData: [] as SearchState[],
      setAllSearchData: setter('allSearchData'),
      searchLocale: null as Locale | null,
      setSearchLocale: setter('searchLocale'),
      showLocationDropdown: false,
      setShowLocationDropdown: setter('showLocationDropdown'),
      searchValue: '',
      setSearchValue: setter('searchValue'),
      filteredSearchResults: [],
      setFilteredSearchResults: (val) =>
        set((state) => {
          return {
            ...state,
            filteredSearchResults: val.filter((a) => !a.hidden),
          };
        }),
      locationInputRef: null,
      setLocationInputRef: setter('locationInputRef'),
      selectedLocation: null,
      setSelectedLocation: setter('selectedLocation'),
    };
  })
);
