import Dropdown from 'components/common/Dropdown';
import BInput from 'components/Form/BInput';
import { useBreakpoint } from 'hooks/useBreakpoints';
import { useLocale } from 'hooks/useLocale';
import useTranslation from 'next-translate/useTranslation';
import { analyticsService } from 'services/AnalyticsService';
import { linkService } from 'services/LinkService';
import { useExperienceSearchState } from 'states/useExperienceSearchState';
import { SearchState } from 'types/search.type';
import SearchItems from './SearchItems';
import { SearchMobileCard } from './SearchMobileCard';

export const SearchExperienceLocationSelector = ({
  onInputChange,
  onLocationSelect,
}: {
  onInputChange: (val: string) => void;
  onLocationSelect: (path: string) => void;
}) => {
  const { t } = useTranslation('navbar');
  const { isMobile } = useBreakpoint();
  const { locale } = useLocale();
  const {
    showLocationDropdown,
    setShowLocationDropdown,
    searchValue,
    filteredSearchResults,
    locationInputRef,
    selectedLocation,
    setSearchValue,
    setSelectedLocation,
  } = useExperienceSearchState();

  const close = () => {
    setShowLocationDropdown(false);
  };

  const closeWithBlur = () => {
    close();
    locationInputRef?.current?.blur();
  };

  const onSearchItemClick = (loc: SearchState) => {
    setSearchValue(loc.name);
    setSelectedLocation(loc);
    setShowLocationDropdown(false);
    const category = linkService.categoryLinkTranslations.find(
      (a) => a.tr === 'tekne-turu'
    )?.[locale];
    const link = linkService.generateCategoryLink({
      category,
      locale,
      locationSlug: loc.slug,
    });
    onLocationSelect(link);
    analyticsService.fireExperienceSearchEvent({
      harbor: loc.name,
    });
  };

  if (isMobile) {
    return (
      <SearchMobileCard
        show={true}
        setShow={setShowLocationDropdown}
        iconType="lifebuoy"
        title={t('search.location.mobile.title')}
        selectedValue={
          selectedLocation
            ? selectedLocation.name
            : t('search.location.mobile.placeholder')
        }
      >
        <>
          <BInput
            type="text"
            placeholder={t('search.title')}
            value={searchValue}
            onChange={(event) => onInputChange(event.target.value)}
            className="mb-3 focus:outline-none active:outline-none"
          />
          <div className="h-[40vh] overflow-auto">
            <SearchItems
              searchResults={filteredSearchResults}
              onClick={onSearchItemClick}
            />
          </div>
        </>
      </SearchMobileCard>
    );
  }
  return (
    <Dropdown
      wrapperClass="mt-2 text-base w-full text-left max-h-60 overflow-y-auto overscroll-contain hidden md:block w-3/4 left-0"
      show={showLocationDropdown}
      onClose={closeWithBlur}
    >
      <SearchItems
        searchResults={filteredSearchResults}
        onClick={onSearchItemClick}
      />
    </Dropdown>
  );
};
