import { Locale } from 'enums/locale';
import { VehicleType } from 'enums/vehicleTypes';
import trI18NCommon from '../../locales/tr/common.json';
import enI18NCommon from '../../locales/en/common.json';
import { getLocalePrefix } from 'utils/getLocalePrefix';
import { slugifyText } from 'utils/slugify';

type LocalizedLinks = Record<Locale, string>;
type VehicleDetailsLinkMap = Record<VehicleType, LocalizedLinks>;
// export type YachtOrBoat = ;
export type CategoryExceptionTypes =
  | 'YACHTRENTAL'
  | 'BOATRENTAL'
  | 'EXPERIENCE';
export type CategoryTypes = CategoryExceptionTypes | VehicleType;

class LinkService {
  constructor() {
    this._vehicleDetailsLinkMap = {} as VehicleDetailsLinkMap;
    for (const key in trI18NCommon.vehicleDetailLinks) {
      this._vehicleDetailsLinkMap[key as VehicleType] = {
        [Locale.TR]: trI18NCommon.vehicleDetailLinks[key as VehicleType],
        [Locale.EN]: enI18NCommon.vehicleDetailLinks[key as VehicleType],
      };
    }
    this.categorySearchRegex = new RegExp(
      this.categoryLinkTranslations
        .map((a) => Object.values(a))
        .flat()
        .join('|')
    );
  }

  private _vehicleDetailsLinkMap: VehicleDetailsLinkMap;

  categoryLinks = Object.freeze([
    'tekne-kiralama',
    'yat-kiralama',
    'motoryat-kiralama',
    'davet-teknesi-kiralama',
    'gulet-kiralama',
    'yelkenli-kiralama',
    'surat-teknesi-kiralama',
    'katamaran-kiralama',
    'tekne-turu',
  ] as const);

  categoryMap = Object.freeze({
    'tekne-kiralama': 'BOATRENTAL',
    'yat-kiralama': 'YACHTRENTAL',
    'motoryat-kiralama': VehicleType.YACHT,
    'davet-teknesi-kiralama': VehicleType.INVITATION_BOAT,
    'gulet-kiralama': VehicleType.GULET,
    'yelkenli-kiralama': VehicleType.SAIL_BOAT,
    'surat-teknesi-kiralama': VehicleType.SPEED_BOAT,
    'katamaran-kiralama': VehicleType.CATAMARAN,
    'tekne-turu': 'EXPERIENCE',
  } as const);

  categoryLinkTranslations = Object.freeze([
    { tr: 'tekne-kiralama', en: 'boat-rental' },
    { tr: 'yat-kiralama', en: 'yacht-rental' },
    { tr: 'motoryat-kiralama', en: 'motoryacht-charter' },
    { tr: 'davet-teknesi-kiralama', en: 'invitation-boat-charter' },
    { tr: 'gulet-kiralama', en: 'gulet-charter' },
    { tr: 'yelkenli-kiralama', en: 'sail-boat-charter' },
    { tr: 'surat-teknesi-kiralama', en: 'speed-boat-charter' },
    { tr: 'katamaran-kiralama', en: 'catamaran-charter' },
    { tr: 'tekne-turu', en: 'boat-tour' },
  ] as const);

  categoryLinkTitleTranslations = Object.freeze({
    'tekne-kiralama': { tr: 'Tekne Kiralama', en: 'Boat Rental' },
    'yat-kiralama': { tr: 'Yat Kiralama', en: 'Yacht Rental' },
    'motoryat-kiralama': { tr: 'Motoryat Kiralama', en: 'Motor Yacht Charter' },
    'davet-teknesi-kiralama': {
      tr: 'Davet Teknesi Kiralama',
      en: 'Invitation Boat Charter',
    },
    'gulet-kiralama': { tr: 'Gulet Kiralama', en: 'Gulet Charter' },
    'yelkenli-kiralama': { tr: 'Yelkenli Kiralama', en: 'Sail Boat Charter' },
    'surat-teknesi-kiralama': {
      tr: 'Sürat Teknesi Kiralama',
      en: 'Speed Boat Charter',
    },
    'katamaran-kiralama': {
      tr: 'Katamaran Kiralama',
      en: 'Catamaran Charter',
    },
    'tekne-turu': {
      tr: 'Tekne Turu',
      en: 'Boat Tour',
    },
  } as const);

  categorySearchRegex = new RegExp(
    this.categoryLinkTranslations
      .map((a) => Object.values(a))
      .flat()
      .join('|')
  );

  getVehicleLink(
    vehicleType: VehicleType,
    vehicleId: number,
    locale: Locale,
    query?: string
  ) {
    let link = `${this._vehicleDetailsLinkMap[vehicleType][locale]}-${vehicleId}`;
    if (query) {
      link += `?${query}`;
    }
    return link;
  }

  translateVehicleLink(
    vehicleLink: string,
    prevLocale: Locale,
    nextLocale: Locale
  ): string {
    const localizedLinks = Object.values(this._vehicleDetailsLinkMap);
    const nextLocaleLinkObject = localizedLinks.find((link) =>
      vehicleLink.includes(link[prevLocale])
    );

    if (nextLocaleLinkObject === undefined) {
      return vehicleLink;
    }

    const splittedLink = vehicleLink.replaceAll('/', '').split('-');
    const vehicleId = splittedLink[splittedLink.length - 1];
    const nextLocaleLink = nextLocaleLinkObject[nextLocale];
    const localePrefix = nextLocale !== Locale.TR ? `/${nextLocale}` : '';
    return `${localePrefix}${nextLocaleLink}-${vehicleId}`;
  }

  getCategoryLinkOption(
    vehicleType: VehicleType | null,
    locale: Locale,
    yachtOrBoat?: CategoryExceptionTypes
  ) {
    if (vehicleType === null && yachtOrBoat) {
      const mapping = {
        YACHTRENTAL: 1,
        BOATRENTAL: 0,
        EXPERIENCE: 8,
      };
      const categoryLink = linkService.categoryLinks[mapping[yachtOrBoat]];
      return {
        label: linkService.categoryLinkTitleTranslations[categoryLink][locale],
        value: linkService.categoryLinkTranslations.find(
          (a) => a.tr === categoryLink
        )![locale],
      };
    }
    const vehicleTypeLink = Object.entries(linkService.categoryMap).find(
      (obj) => vehicleType === obj[1]
    );
    if (vehicleTypeLink) {
      vehicleType;
      return {
        label:
          linkService.categoryLinkTitleTranslations[
            vehicleTypeLink[0] as keyof typeof linkService.categoryLinkTitleTranslations
          ][locale],
        value: linkService.categoryLinkTranslations.find(
          (a) => a.tr === vehicleTypeLink[0]
        )![locale],
      };
    }
    return { label: '', value: '' };
  }

  generateCategoryLink({
    category,
    locationSlug,
    locale,
  }: {
    category?: string;
    locationSlug?: string;
    locale: Locale;
  }) {
    let link = getLocalePrefix(locale);
    if (locationSlug) {
      link += locationSlug + '-';
    }
    link += category ?? linkService.categoryLinkTranslations[0][locale];
    return link;
  }

  extractLocationFromCategoryUrl(url: string) {
    const categoryMatch = (url || '').match(this.categorySearchRegex);

    if (categoryMatch && (categoryMatch.index || 0) !== 1) {
      const matchResult = categoryMatch[0];

      return {
        category: matchResult,
        location: url.substring(0, categoryMatch.index! - 1),
      };
    }
    return null;
  }

  getRealVehicleCategory(category: CategoryTypes): VehicleType | undefined {
    return ['BOATRENTAL', 'YACHTRENTAL', 'EXPERIENCE'].includes(category)
      ? undefined
      : (category as VehicleType);
  }

  getExperienceLink(
    title: string,
    id: number,
    locale: Locale,
    includePathName = true
  ) {
    const prefix = includePathName
      ? locale === Locale.TR
        ? '/tekne-turlari/'
        : '/en/boat-tours/'
      : '';
    return prefix + slugifyText(title) + '-' + id;
  }

  translateExperienceLink(slug: string, locale: Locale) {
    const prefix = locale === Locale.TR ? '/tekne-turlari/' : '/en/boat-tours/';
    return prefix + slug;
  }

  isExperiencePage(asPath: string) {
    return ['tekne-tur', 'boat-tour'].some((a) => asPath.includes(a));
  }
}

export const linkService = new LinkService();
