import BButton from 'components/common/BButton';
import BImage from 'components/common/BImage';
import TranslatedLink from 'components/common/TranslatedLink';
import { useRouter } from 'next-translate-routes';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';
import styles from './Footer.module.css';

const Footer: FunctionComponent = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const tekneviaSection: FooterLinkList = {
    title: 'footer.tekneviaSection.title',
    links: [
      {
        label: 'footer.tekneviaSection.about.label',
        url: 'footer.tekneviaSection.about.url',
      },
      {
        label: 'footer.tekneviaSection.pressMedia.label',
        url: 'footer.tekneviaSection.pressMedia.url',
      },
      {
        label: 'footer.tekneviaSection.campaigns.label',
        url: 'footer.tekneviaSection.campaigns.url',
      },
      {
        label: 'footer.tekneviaSection.help.label',
        url: 'footer.tekneviaSection.help.url',
      },
      {
        label: 'footer.tekneviaSection.partner.label',
        url: 'footer.tekneviaSection.partner.url',
      },
      {
        label: 'footer.tekneviaSection.terms.label',
        url: 'footer.tekneviaSection.terms.url',
      },
      {
        label: 'footer.tekneviaSection.cancellationTerms.label',
        url: 'footer.tekneviaSection.cancellationTerms.url',
      },
      {
        label: 'footer.tekneviaSection.kvkk.label',
        url: 'footer.tekneviaSection.kvkk.url',
      },
    ],
  };

  const organizationsSection: FooterLinkList = {
    title: 'footer.organizationsSection.title',
    links: [
      {
        label: 'footer.organizationsSection.birthday.label',
        url: 'footer.organizationsSection.birthday.url',
      },
      {
        label: 'footer.organizationsSection.proposal.label',
        url: 'footer.organizationsSection.proposal.url',
      },
      {
        label: 'footer.organizationsSection.valentines.label',
        url: 'footer.organizationsSection.valentines.url',
      },
      {
        label: 'footer.organizationsSection.wedding.label',
        url: 'footer.organizationsSection.wedding.url',
      },
      {
        label: 'footer.organizationsSection.bachelor.label',
        url: 'footer.organizationsSection.bachelor.url',
      },
      {
        label: 'footer.organizationsSection.babyShower.label',
        url: 'footer.organizationsSection.babyShower.url',
      },
      {
        label: 'footer.organizationsSection.anniversary.label',
        url: 'footer.organizationsSection.anniversary.url',
      },
      {
        label: 'footer.organizationsSection.engagement.label',
        url: 'footer.organizationsSection.engagement.url',
      },
      {
        label: 'footer.organizationsSection.graduation.label',
        url: 'footer.organizationsSection.graduation.url',
      },
      {
        label: 'footer.organizationsSection.afterParty.label',
        url: 'footer.organizationsSection.afterParty.url',
      },
      {
        label: 'footer.organizationsSection.party.label',
        url: 'footer.organizationsSection.party.url',
      },
      {
        label: 'footer.organizationsSection.businessLunch.label',
        url: 'footer.organizationsSection.businessLunch.url',
      },
      {
        label: 'footer.organizationsSection.launch.label',
        url: 'footer.organizationsSection.launch.url',
      },
      {
        label: 'footer.organizationsSection.corporateEntertainment.label',
        url: 'footer.organizationsSection.corporateEntertainment.url',
      },
      {
        label: 'footer.organizationsSection.bosphorus.label',
        url: 'footer.organizationsSection.bosphorus.url',
      },
      {
        label: 'footer.organizationsSection.islands.label',
        url: 'footer.organizationsSection.islands.url',
      },
      {
        label: 'footer.organizationsSection.fishing.label',
        url: 'footer.organizationsSection.fishing.url',
      },
      {
        label: 'footer.organizationsSection.islandsSwimming.label',
        url: 'footer.organizationsSection.islandsSwimming.url',
      },
      {
        label: 'footer.organizationsSection.blackSeaSwimming.label',
        url: 'footer.organizationsSection.blackSeaSwimming.url',
      },
    ],
  };

  const popularLocationsSection: FooterLinkList = {
    title: 'footer.popularLocationsSection.title',
    links: [
      {
        label: 'footer.popularLocationsSection.istanbul.label',
        url: 'footer.popularLocationsSection.istanbul.url',
      },
      {
        label: 'footer.popularLocationsSection.bodrum.label',
        url: 'footer.popularLocationsSection.bodrum.url',
      },
      {
        label: 'footer.popularLocationsSection.gocek.label',
        url: 'footer.popularLocationsSection.gocek.url',
      },
      {
        label: 'footer.popularLocationsSection.fethiye.label',
        url: 'footer.popularLocationsSection.fethiye.url',
      },
    ],
  };

  const boatTypesSection: FooterLinkList = {
    title: 'footer.optionsSection.title',
    links: [
      {
        label: 'footer.optionsSection.rentalBoats.label',
        url: 'footer.optionsSection.rentalBoats.url',
      },
      {
        label: 'footer.optionsSection.guletCharter.label',
        url: 'footer.optionsSection.guletCharter.url',
      },
      {
        label: 'footer.optionsSection.motoryachtCharter.label',
        url: 'footer.optionsSection.motoryachtCharter.url',
      },
    ],
  };

  const sections: FooterLinkList[] = [
    organizationsSection,
    popularLocationsSection,
    boatTypesSection,
  ];

  return (
    <footer className="bg-white">
      <div className="tkv-container mx-auto grid grid-cols-1 justify-between gap-8 py-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div>
          <div className={styles['title']}>{t(tekneviaSection.title)}</div>
          <div className="mt-4 flex flex-col gap-2 text-sm">
            <nav className="flex flex-col gap-2">
              {tekneviaSection.links.map((link, idx) => (
                <TranslatedLink
                  href={t(link.url)}
                  key={`link-${idx}`}
                  prefetch={false}
                >
                  <a className="hover:underline">{t(link.label)}</a>
                </TranslatedLink>
              ))}
            </nav>
            <div className="my-2">
              <a
                href={t('footer.tekneviaSection.blog.url')}
                target="_blank"
                rel="noreferrer"
              >
                <BButton priority="outlined">
                  {t('footer.tekneviaSection.blog.label')}
                </BButton>
              </a>
            </div>
            <div>
              <a href="mailto:info@teknevia.com" className="hover:underline">
                info@teknevia.com
              </a>
            </div>
            <div>
              <a href="tel:+908503081562" className="hover:underline">
                +90 850 308 15 62
              </a>
            </div>
            <div className={styles['app-image']}>
              <a
                href={t('footer.tekneviaSection.appStoreLink')}
                rel="nofollow noreferrer noopener"
                target="_blank"
                title="App Store"
              >
                <BImage
                  src={`https://cdn.teknevia.com/common/mail/mobile-app/app-store-${locale}.png`}
                  layout="fixed"
                  alt="App Store"
                  width={152}
                  height={40}
                  objectFit="contain"
                />
              </a>
            </div>
            <div className={styles['app-image']}>
              <a
                href="https://play.google.com/store/apps/details?id=com.teknevia.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                rel="nofollow noreferrer noopener"
                target="_blank"
                title="Google Play Store"
              >
                <BImage
                  src={`https://cdn.teknevia.com/common/mail/mobile-app/play-store-${locale}.png`}
                  layout="fixed"
                  alt="Google Play Store"
                  width={134}
                  height={40}
                />
              </a>
            </div>
            <div className={styles['app-image']}>
              {/*eslint-disable-next-line*/}
              <a
                href="https://www.tursab.org.tr/tr/ddsv"
                rel="nofollow noopener"
                target="_blank"
                title="Türsab"
              >
                <BImage
                  src={`https://cdn.teknevia.com/storefront/common/tursab-dds-17900-${locale}.png`}
                  layout="fixed"
                  alt="Türsab"
                  width={145}
                  height={61}
                />
              </a>
            </div>
          </div>
        </div>
        {sections.map((section, idx) => (
          <div key={`section-${idx}`}>
            <div className={styles['title']}>{t(section.title)}</div>
            <nav className="mt-4 flex flex-col gap-2 text-sm">
              {section.links.map((link, idx) => (
                <TranslatedLink
                  href={t(link.url)}
                  key={`link-${idx}`}
                  prefetch={false}
                >
                  <a className="hover:underline">{t(link.label)}</a>
                </TranslatedLink>
              ))}
            </nav>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;

interface FooterLinkList {
  title: string;
  links: {
    label: string;
    url: string;
  }[];
}
